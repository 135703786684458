import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import logo from '../images/treuefuchs.svg';

export default function Index() {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Box sx={{
          textAlign: 'center'
        }} component="header" mb={3}>
          <img style={{ height: '10vmin' }} src={logo} alt="treuefuchs logo"/>
          <Typography sx={{
            color: 'secondary.main',
            fontWeight: 600,
          }} variant="h1" component="h1" gutterBottom>treuefuchs</Typography>
        </Box>
        
        <Typography variant="h2" component="h2" gutterBottom>
          Impressum
        </Typography>

        <Typography gutterBottom>Informationspflicht laut § 5 TMG.</Typography>
        <Typography gutterBottom>
          treuefuchs GbR - Marco Eckert, Robert Merlin
          <br />
          Humboldtstr. 1<br />
          76131 Karlsruhe
          <br />
          Deutschland
        </Typography>
        <Typography gutterBottom>E-Mail: info@treuefuchs.app</Typography>
        <Typography gutterBottom>Vertreten durch die Gesellschafter Marco Eckert, Robert Merlin</Typography>
      </Box>
    </Container>
  );
};
